import { render, staticRenderFns } from "./ProductShippersList.vue?vue&type=template&id=a3798f0c&"
import script from "./ProductShippersList.js?vue&type=script&lang=js&"
export * from "./ProductShippersList.js?vue&type=script&lang=js&"
import style0 from "./ProductShippersList.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ProductShippersList.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fbase%2Fcomponents%2Fproduct%2FProductShippersList%2FProductShippersList.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports