<script>
export default {
  props: {
    menuItem: {
      type: Object,
      default: null,
    },
  },
  computed: {
    menuIsVisible() {
      return this.menuBreadcrumbs[0] && this.menuBreadcrumbs[0].children && this.menuBreadcrumbs[0].children.length > 0
    },
    menuTitle() {
      return this.menuBreadcrumbs[0] ? this.menuBreadcrumbs[0] : null
    },
    menuLinks() {
      return this.menuBreadcrumbs[0] && this.menuBreadcrumbs[0].children ? this.menuBreadcrumbs[0].children : []
    },
    menuBreadcrumbs() {
      if (this.menuItem) {
        const breadcrumbs = []
        let item = this.menuItem
        do {
          breadcrumbs.unshift(item)
          item = item.parent
        } while (item)
        return breadcrumbs
      }
      return []
    },
  },
  created() {
    this.$store.commit('menu/SET_ACTIVE_MENU_ITEM', this.menuItem)
  },
  destroyed() {
    this.$store.commit('menu/SET_ACTIVE_MENU_ITEM', null)
  },
}
</script>
