import BadgeWidgets from '~/themes/base/components/shop/BadgeWidgets'

export default {
  extends: BadgeWidgets,
  data() {
    return {
      badges: [
        {
          name: 'heureka',
          html: `<div id="showHeurekaBadgeHere-11"></div>`,
          script: () => {
            if (this.isMobileDetected || this.isTabletOrMobileDetected) {
              this.badges[0].html = `<a href="https://obchody.heureka.sk/eros-sk/recenze/" target="_blank" title="${this.$t(
                'BadgeWidgets.heureka.linkTitle',
              )}"><img src="/assets/images/heureka-sk.png"></a>`
            } else {
              const HEUREKA_PUBLIC_KEY = '3E3B2273173EC51F1CE0294DFB5190D4'
              let e = document.querySelector('head'),
                s = document.createElement('script')
              s.setAttribute('type', 'text/javascript')
              s.setAttribute('async', 'true')
              s.setAttribute('src', 'https://sk.im9.cz/direct/i/gjs.php?n=wdgt&sak=' + HEUREKA_PUBLIC_KEY)
              e.parentNode.appendChild(s)
              s.onload = function() {
                window._hwq.push(['setKey', HEUREKA_PUBLIC_KEY])
                window._hwq.push(['showWidget', '11', '5193', 'EROS', 'eros-sk'])
              }
            }
          },
        },
      ],
    }
  },
}
