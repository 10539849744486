import AppTitle from '@theme/components/atom/AppTitle'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppTitle,
    Wysiwyg,
  },
  computed: {
    materials() {
      return this.$store.state.product.pageData.detail.productMaterials
    },
  },
}
