<template>
  <PageProductDetail
    v-if="product"
    :accessory-products="accessoryProducts"
    :alternative-products="alternativeProducts"
    :comments="comments"
    :configurator-data="configuratorData"
    :product-reviews="productReviews"
    :product-reviews-summary="productReviewsSummary"
    :show-product-reviews-summary="showProductReviewsSummary"
    :related-categories="relatedCategories"
    :related-products="relatedProducts"
    :menu-item="menuItem"
    :product="product"/>
</template>

<script>
import { get } from 'lodash'
import { hookDefinitions, hookDispatch } from '~/hooks'
import AppRouteMixin from '~/mixins/AppRouteMixin'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import PageProductDetail from '@theme/components/product/PageProductDetail'

const PAGE = {
  name: 'RouteProductDetail',
  nuxtI18n: {
    paths: {
      cs: '/produkt/:slug',
      de: '/produkt/:slug',
      en: '/product/:slug',
      sk: '/produkt/:slug',
    },
  },
  components: {
    PageProductDetail,
  },
  mixins: [AppRouteMixin, ImageUrlMixin],
  mounted() {
    this.$store.dispatch('product/ADD_LAST_VISITED', this.product.id)
  },
  async asyncData(context) {
    const { store, params, query, res, app, error } = context
    if (process.server) {
      res.set('X-Inspishop-Cache-Control', `public, max-age=${app.$env.STATELESS_TTL}`)
    }
    const product = await store.dispatch('product/LOAD_PRODUCT', {
      slug: params.slug,
    })
    if (!product) {
      return app.$pageNotFound({ slug: params.slug, type: 'products' })
    }
    if (product && product.error) {
      return error({ statusCode: product.error.statusCode })
    }

    // Deleted reset because it made images reload

    store.commit('locale/SET_CANONICALS', {
      canonicalSlugs: product.canonicalSlugs,
      nuxtI18nPaths: PAGE.nuxtI18n.paths,
    })

    const data = {
      accessoryProducts:
        app.$themeSettings.components.PageProductDetail.accessoryProducts.enable && product.accessories.length > 0
          ? store.dispatch('product/LOAD_PRODUCTS', { ids: product.accessories })
          : [],
      alternativeProducts:
        (app.$themeSettings.components.PageProductDetail.alternativeProducts.enable ||
          app.$themeSettings.components.PageProductDetail.showColorVariants) &&
        product.alternatives.length > 0
          ? store.dispatch('product/LOAD_PRODUCTS', { ids: product.alternatives })
          : [],
      comments:
        app.$themeSettings.components.ProductComments.enable &&
        app.$themeSettings.components.PageProductDetail.showProductComments
          ? store.dispatch('product/LOAD_PRODUCT_COMMENTS', { productId: product.id })
          : [],
      fetchParameters: store.dispatch('product/FETCH_PARAMETERS', { product }),
      configuratorData: store.dispatch('configurator/LOAD_CONFIGURATOR', {
        id: product.content._id,
      }),
      menuItem: product.categoryId
        ? store
            .dispatch('menu/FIND_MENU_ITEMS', { ids: [product.categoryId, ...product.categoryIds], menu: 'mainMenu' })
            .then(items => items[0])
        : null,
      product,
      productReviews:
        store.state.globals.settings.functions.products_user_reviews &&
        app.$themeSettings.components.PageProductDetail.reviews.enable
          ? store.dispatch('product/LOAD_REVIEWS', {
              onlyWithReview: app.$themeSettings.components.PageProductDetail.reviews.onlyWithReview ? 1 : 0,
              page: query && !isNaN(query.r) ? Number(query.r) + 1 : 1,
              productId: product.id,
              perPage: app.$themeSettings.components.PageProductDetail.reviews.initialCount,
            })
          : {},
      relatedCategories:
        app.$themeSettings.components.PageProductDetail.showRelatedCategories && product.categoryIds.length > 0
          ? store.dispatch('category/LOAD_CATEGORIES', { ids: product.categoryIds })
          : [],
      relatedProducts:
        product.related.length > 0 ? store.dispatch('product/LOAD_PRODUCTS', { ids: product.related }) : [],
    }

    await hookDispatch(hookDefinitions.PAGE.PRODUCT.DETAIL.ASYNC_DATA_PROMISES, { context, data, product })
    let result = await Promise.allValues(data)
    if (app.$contentSettings.functions.ssrPropertyPicker && process.server) {
      const mixin = (await import('~/mixins/SSRPropertyPickMixin.vue')).default

      result = mixin.methods.pickKeysFromPayload({
        payload: result,
        rules: mixin.computed.ssrPayloadRules(),
        pickers: mixin.computed.ssrPayloadPickers(),
      })
    }

    await hookDispatch(hookDefinitions.PAGE.PRODUCT.DETAIL.ASYNC_DATA_PREPROCESS, { data: result })
    await hookDispatch(hookDefinitions.PAGE.PRODUCT.DETAIL.ASYNC_DATA_PROCESS, { data: result })
    await app.apolloProvider.defaultClient.cache.reset()
    let productReviewsSummary = get(result.productReviews, 'meta.custom.summary', null)
    result.showProductReviewsSummary =
      store.state.globals.settings.functions.products_user_reviews &&
      app.$themeSettings.components.PageProductDetail.reviews.enable &&
      productReviewsSummary &&
      productReviewsSummary.totalCount > 0
    result.productReviewsSummary = productReviewsSummary
    return result
  },
}
export default PAGE
</script>
