<script>
import Component from '~/sites/virtshop/pages/product/_slug'
export default {
  extends: Component,
  nuxtI18n: {
    paths: {
      cs: '/produkt/:slug',
      en: '/product/:slug',
      sk: '/produkt/:slug',
    },
  },
}
</script>
