import { get, isEmpty } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import Badge from '@theme/components/atom/Badge'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import ProductMixin from '~/mixins/ProductMixin'
import Tag from '@theme/components/atom/Tag'
import WishListIcon from '@theme/components/product/WishListIcon'

export default {
  components: {
    AppImage,
    Badge,
    Tag,
    WishListIcon,
  },
  mixins: [ImageUrlMixin, ProductMixin],
  data() {
    return {
      defaultCountOfMediaToShow: 5,
      lightboxComponent: null,
      minimumCountOfMediaToShowThumbnails: 1,
      selectedVariantImageBreakpoints: null,
      showingAllMedia: false,
    }
  },
  props: {
    product: Object,
    displayedVariant: Object,
  },
  computed: {
    hiddenMediaCount() {
      return this.lightBoxItems.length - 1 - this.defaultCountOfMediaToShow
    },
    lightBoxItems() {
      const items = []
      const content = isEmpty(this.displayedVariant.content) ? this.product.content : this.displayedVariant.content
      items.push({
        src: this.mainImage,
        title: '',
        type: 'image',
      })
      if ('media_360image' in content && content.media_360image.length > 0) {
        items.push({
          src: content.media_360image[0].fileKey,
          media_object: content.media_360image,
          title: '',
          type: '360image',
        })
      }
      if ('product_video' in content) {
        content.product_video.forEach(video => {
          if (!video.video_url) return
          items.push({
            src: video.video_url,
            media_object: video,
            title: '',
            type: 'video',
          })
        })
      }
      const images = get(this.displayedVariant, 'images.length') ? this.displayedVariant.images : this.product.images
      images.forEach(image => {
        items.push({
          src: image.src,
          title: '',
          type: 'image',
        })
      })
      return items
    },
    mainImage() {
      return (this.displayedVariant && this.displayedVariant.mainImage) || this.product.mainImage
    },
    percentageDiscount() {
      return Math.round(this.displayedVariant.prices.percentageDiscount)
    },
  },
  methods: {
    async openMedia(index) {
      import('@theme/components/utils/LightboxGallery').then(component => {
        this.lightboxComponent = component.default
        this.$nextTick(() => {
          this.$refs.gallery.open(index)
        })
      })
    },
    showAllImages() {
      this.showingAllMedia = true
    },
  },
}
