import { find, get, orderBy, uniqBy } from 'lodash'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AddToCart from '@theme/components/cart/AddToCart'
import AppImage from '@theme/components/atom/AppImage'
import AppNumber from '@theme/components/atom/AppNumber'
import AppTitle from '@theme/components/atom/AppTitle'
import Badge from '@theme/components/atom/Badge'
import BonusClubValuePrinter from '@theme/components/content/BonusClubValuePrinter'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import Carousel from '@theme/components/utils/Carousel'
import CompareButton from '@theme/components/product/CompareButton'
import ContactBox from '@theme/components/shop/ContactBox'
import DeliveryDate from '@theme/components/shop/DeliveryDate'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import Divider from '@theme/components/molecule/Divider'
import Dropdown from '@theme/components/utils/Dropdown'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import MenuItemPageMixin from '~/mixins/MenuItemPageMixin'
import PricesMixin from '~/mixins/PricesMixin'
import ProductAvailability from '@theme/components/product/ProductAvailability'
import ProductAvailabilityWatcher from '@theme/components/product/ProductAvailabilityWatcher'
import ProductBox from '@theme/components/product/ProductBox'
import ProductColorButton from '@theme/components/product/ProductColorButton'
import ProductComments from '@theme/components/product/ProductComments'
import ProductConfigurator from '@theme/components/product/ProductConfigurator'
import ProductDateOfStorage from '@theme/components/product/ProductDateOfStorage'
import ProductDetailAboveProductDetailImagesHook from '@theme/components/product/ProductDetailAboveProductDetailImagesHook'
import ProductDetailBelowContentHook from '@theme/components/product/ProductDetailBelowContentHook'
import ProductDetailBelowShortDescriptionHook from '@theme/components/product/ProductDetailBelowShortDescriptionHook'
import ProductDetailImages from '@theme/components/product/ProductDetailImages'
import ProductMetersCalculator from '@theme/components/product/ProductMetersCalculator'
import ProductMixin from '~/mixins/ProductMixin'
import ProductReviewList from '@theme/components/product/ProductReviewList'
import ProductShippersList from '@theme/components/product/ProductShippersList'
import ProductTeaser from '@theme/components/product/ProductTeaser'
import ProductWarehouseAvailability from '@theme/components/product/ProductWarehouseAvailability'
import ProductsRecommended from '@theme/components/product/ProductsRecommended'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import StarRating from '@theme/components/utils/StarRating'
import Sticky from '@theme/components/utils/Sticky'
import Tag from '@theme/components/atom/Tag'
import TooltipOpener from '@theme/components/molecule/TooltipOpener'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'
import VerticalMenu from '@theme/components/shop/VerticalMenu'
import Wysiwyg from '@theme/components/utils/Wysiwyg'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  components: {
    AddToCart,
    AppImage,
    AppNumber,
    AppTitle,
    Badge,
    BonusClubValuePrinter,
    Breadcrumbs,
    Carousel: hydrateWhenVisible(Carousel),
    CompareButton,
    ContactBox: hydrateWhenVisible(ContactBox),
    DeliveryDate: hydrateWhenVisible(DeliveryDate),
    Divider,
    Dropdown,
    LayoutBase,
    ProductAvailability,
    ProductAvailabilityWatcher: hydrateWhenVisible(ProductAvailabilityWatcher),
    ProductBox: hydrateWhenVisible(ProductBox),
    ProductColorButton: hydrateWhenVisible(ProductColorButton),
    ProductComments: hydrateWhenVisible(ProductComments),
    ProductConfigurator,
    ProductDateOfStorage,
    ProductDetailAboveProductDetailImagesHook: hydrateWhenVisible(ProductDetailAboveProductDetailImagesHook),
    ProductDetailBelowContentHook: hydrateWhenVisible(ProductDetailBelowContentHook),
    ProductDetailBelowShortDescriptionHook: hydrateWhenVisible(ProductDetailBelowShortDescriptionHook),
    ProductDetailImages: hydrateWhenVisible(ProductDetailImages),
    ProductMetersCalculator,
    ProductReviewList: hydrateWhenVisible(ProductReviewList),
    ProductShippersList: hydrateWhenVisible(ProductShippersList),
    ProductTeaser: hydrateWhenVisible(ProductTeaser),
    ProductWarehouseAvailability: hydrateWhenVisible(ProductWarehouseAvailability),
    ProductsRecommended: hydrateWhenVisible(ProductsRecommended),
    ProductsVisited: hydrateWhenVisible(ProductsVisited),
    StarRating,
    Sticky: hydrateWhenVisible(Sticky),
    VerticalMenu: hydrateWhenVisible(VerticalMenu),
    Tag,
    TooltipOpener: hydrateWhenVisible(TooltipOpener),
    Wysiwyg,
  },
  props: {
    accessoryProducts: Array,
    comments: Array,
    configuratorData: Object,
    multiplyPricesByQuantity: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.PageProductDetail.multiplyPricesByQuantity
      },
    },
    productReviews: Object,
    relatedCategories: Array,
    relatedProducts: Array,
    alternativeProducts: Array,
    product: Object,
    productReviewsSummary: Object,
    showProductReviewsSummary: Boolean,
  },
  data() {
    return {
      activeGift: null,
      allVariantsOpened: false,
      cardMainTitle: {
        class: 'Title--gama',
        level: 2,
      },
      defaultCountOfVariantsToShow: this.$themeSettings.components.PageProductDetail.countOfVariantsToShow,
      displayedVariant: null,
      fittingGuideModalComponent: null,
      giftModalComponent: null,
      iconBoxes:
        typeof this.$t('globals.PageProductDetail.iconBoxes') === 'object' &&
        Object.keys(this.$t('globals.PageProductDetail.iconBoxes')).length > 0
          ? this.$t('globals.PageProductDetail.iconBoxes')
          : [],
      iconBoxesIcons: {
        gift: require('@icon/gift.svg?raw'),
        'return-delivery': require('@icon/return-delivery.svg?raw'),
        'shield-o': require('@icon/shield-o.svg?raw'),
      },
      isProductConfiguratorSticky: false,
      lightboxComponent: null,
      quantity: 1,
      selectedVariant: null,
      tabActive: 'description',
    }
  },
  head() {
    return this.getSeoHead()
  },
  mixins: [DetectMobileMixin, MenuItemPageMixin, PricesMixin, ProductMixin, ImageUrlMixin, SeoMixin, UtilityGridMixin],
  created() {
    this.registerViewEvents([this.sendViewEventProduct])
    const variantsCount = this.product.variants ? this.product.variants.length : 0
    const amountMin = get(this.displayedVariant, 'availability.amountMin')
    this.quantity = amountMin || 1
    this.fetchQuantityFromUrl()
    this.fetchVariantFromUrl()
    if (
      !this.selectedVariant &&
      this.displayedVariant &&
      (this.$themeSettings.components.PageProductDetail.selectDefaultVariant || variantsCount < 2)
    ) {
      this.selectedVariant = this.displayedVariant
    }
  },
  computed: {
    alternativeColorVariants() {
      return orderBy([...this.alternativeProducts, this.product], 'id')
    },
    bestRating() {
      let rating = 0
      if (!this.$themeSettings.components.PageProductDetail.reviews.enable) {
        return rating
      }
      this.productReviews.data.forEach(item => {
        if (item.rating > rating) {
          rating = item.rating
        }
      })
      return rating
    },
    branchAvailableCount() {
      const product = this.variantsAvailabilityInWarehouses?.[this.displayedVariant.id] || {}
      const branches =
        product?.virtual?.reduce((set, branch) => {
          if (branch.stock > 0) {
            set.add(branch.id)
          }

          return set
        }, new Set()) || new Set()

      return branches.size
    },
    brand() {
      return false // Return parameter value by id on project by extending PageProductDetail.js
    },
    breadcrumbs() {
      let breadcrumbs = [...this.menuBreadcrumbs]
      breadcrumbs.push({
        title: this.product.name,
        path: this.productPath,
      })
      return breadcrumbs
    },
    catalogCode() {
      return (this.displayedVariant && this.displayedVariant.catalogCode) || this.product.catalogCode
    },
    defaultVariant() {
      const defaultVariantFromDatabase = find(this.product.variants, v => v.id === this.product.defaultVariantId)
      if (!defaultVariantFromDatabase.availability.isAvailable) {
        const anotherAvailableVariantFromDatabase = find(
          this.product.variants,
          variant => variant.availability.isAvailable,
        )
        if (anotherAvailableVariantFromDatabase) {
          return anotherAvailableVariantFromDatabase
        }
      }
      return defaultVariantFromDatabase
    },
    discountPercentage() {
      return Math.round(this.displayedVariant.prices.percentageDiscount)
    },
    discountTotal() {
      return this.displayedVariant.prices.originalWithTax - this.displayedVariant.prices.unitWithTax
    },
    displayedPerex() {
      if (
        this.$themeSettings.components.PageProductDetail.overridePerexByActiveVariantIfExists &&
        this.displayedVariant &&
        this.displayedVariant.perex
      ) {
        return this.displayedVariant.perex
      }
      return this.product.perex
    },
    displayedDescription() {
      if (
        this.$themeSettings.components.PageProductDetail.overrideDescriptionByActiveVariantIfExists &&
        this.displayedVariant &&
        this.displayedVariant.description
      ) {
        return this.displayedVariant.description
      }
      return this.product.description
    },
    hasConfigurator() {
      return get(this.configuratorData, 'product_configurations.[0]') || false
    },
    itemFormattedPrices() {
      return {
        ...this.getFormattedPrices({
          prices: this.displayedVariant.prices,
          ...(this.multiplyPricesByQuantity ? { amount: this.quantity } : {}),
        }),
        original: this.getOriginalPrice({
          prices: (this.activeVariant || {}).defaultPrices,
          originalWithKey: 'originalWithTax',
          originalWithoutKey: 'originalWithoutTax',
          ...(this.multiplyPricesByQuantity ? { amount: this.quantity } : {}),
        }),
      }
    },
    metersParameter() {
      return this.parameters.find(parameter => parameter.entityId === this.$contentSettings.metersParameterId)
    },
    productBrands() {
      return this.$store.state.product.pageData.detail.productBrands
    },
    parameters() {
      let parameters = []
      if (this.product.content && this.product.content.parameters && Array.isArray(this.product.content.parameters)) {
        parameters = JSON.parse(JSON.stringify(this.product.content.parameters))
      }
      if (this.displayedVariant && this.displayedVariant.content && this.displayedVariant.content.parameters) {
        for (let variantParameter of this.displayedVariant.content.parameters) {
          const parameterIndex = parameters.findIndex(parameter => parameter.entityId === variantParameter.entityId)
          if (parameterIndex !== -1) {
            parameters.splice(parameterIndex, 1, variantParameter)
          } else {
            parameters.push(variantParameter)
          }
        }
      }
      return uniqBy(parameters, 'entityId')
    },
    pricePrimaryLabel() {
      const { label, withTax } = this.getPriceSettings('primary')
      return label && this.$t('PageProductDetail.' + (withTax ? 'priceWithTax' : 'priceWithoutTax'))
    },
    priceSecondaryLabel() {
      const { label, withTax } = this.getPriceSettings('secondary')
      return label && this.$t('PageProductDetail.' + (withTax ? 'priceWithTax' : 'priceWithoutTax'))
    },
    productPath() {
      const query = this.$route.query.variant_id ? { variant_id: parseInt(this.$route.query.variant_id) } : {}
      return this.getProductPath(this.product, query)
    },
    productUnits() {
      return this.quantityMultiplier
        ? this.$t('globals.defaultMetricProductUnit')
        : this.$t('globals.defaultProductUnit')
    },
    quantityMultiplier() {
      return Number(get(this.metersParameter, 'values') || 1)
    },
    schemaAvailabilityUrl() {
      const availabilityCode = this.displayedVariant.availability.isAvailable
        ? this.displayedVariant.availability.availabilityCode
        : this.displayedVariant.availability.soldAvailabilityCode
      let value
      switch (availabilityCode) {
        case 'discontinued':
          value = 'Discontinued'
          break
        case 'limited':
          value = 'LimitedAvailability'
          break
        case 'in-stock':
          value = 'InStock'
          break
        case 'in-store-only':
          value = 'InStoreOnly'
          break
        case 'online-only':
          value = 'OnlineOnly'
          break
        case 'out-of-stock':
          value = 'OutOfStock'
          break
        case 'pre-order':
          value = 'PreOrder'
          break
        case 'sold-out':
          value = 'SoldOut'
          break
      }
      return `http://schema.org/${value}`
    },
    showColorVariants() {
      return this.$themeSettings.components.PageProductDetail.showColorVariants && this.alternativeProducts.length > 0
    },
    showMetersCalculator() {
      return this.$themeSettings.components.PageProductDetail.showMetersCalculator && this.metersParameter
    },
    showProductParametersInTable() {
      return (
        this.$themeSettings.components.PageProductDetail.parametersTable.enable &&
        this.$themeSettings.components.PageProductDetail.parametersTable.showProductParameters
      )
    },
    showOfferAddToCart() {
      return !this.hasConfigurator
    },
    showOfferAvailability() {
      return this.$themeSettings.components.PageProductDetail.showAvailability
    },
    showOfferAvailabilityInWarehouses() {
      return (
        this.$themeSettings.components.PageProductDetail.showAvailabilityInWarehouses &&
        (this.displayedVariant.availability.isAvailable ||
          this.variantsAvailabilityInWarehouses[this.displayedVariant.id].virtual.length > 0)
      )
    },
    showOfferAvailabilityInWarehousesInDeliveryOptions() {
      return (
        this.$themeSettings.components.PageProductDetail.showAvailabilityInWarehousesInDeliveryOptions &&
        (this.displayedVariant.availability.isAvailable ||
          this.variantsAvailabilityInWarehouses[displayedVariant.id].virtual.length > 0)
      )
    },
    showOfferDeliveryOptions() {
      return (
        this.$themeSettings.components.PageProductDetail.showDeliveryOptions &&
        this.displayedVariant.availability.isAvailable &&
        this.$store.state.cart.isLoaded
      )
    },
    showOfferDateOfStorage() {
      return this.$themeSettings.components.PageProductDetail.showDateOfStorage
    },
    showOfferGifts() {
      return this.product.gifts.length > 0
    },
    showOfferPrices() {
      return (
        !this.$themeSettings.components.PageProductDetail.parametersTable.enable ||
        !this.$themeSettings.components.PageProductDetail.parametersTable.prices.price.enable
      )
    },
    showOfferSection() {
      return (
        this.showOfferAvailability ||
        this.showOfferDeliveryOptions ||
        this.showOfferAvailabilityInWarehouses ||
        this.showOfferAddToCart ||
        this.showOfferPrices
      )
    },
    showOfferSeparator() {
      return this.showOfferAvailability || this.showOfferAvailabilityInWarehouses || this.showOfferDeliveryOptions
    },
    tabsEnabled() {
      return this.getEnabledTabsAccordingToProjectSettings()
    },
  },
  mounted() {
    this.$nuxt.$on('addToCartFromUrl', this.addToCartFromUrl)
  },
  destroyed() {
    this.$nuxt.$off('addToCartFromUrl', this.addToCartFromUrl)
  },
  methods: {
    async addToCartFromUrl() {
      this.$nextTick(() => {
        this.$refs.AddToCart.addToCart(this.activeVariant.id)
      })
    },
    sendViewEventProduct() {
      this.sendViewEvent(eventDefinitions.PRODUCT.PAGE_VIEW, {
        product: this.product,
        variant: this.displayedVariant,
      })
    },
    getSeoLinkCustom() {
      return [
        {
          rel: 'preload',
          as: 'image',
          href: this.imageUrl({
            src: this.product.mainImage,
            width: this.$themeSettings.components.ProductDetailImages.image.width,
            height: this.$themeSettings.components.ProductDetailImages.image.height,
            webP: true,
          }),
        },
      ]
    },
    getEnabledTabsAccordingToProjectSettings() {
      let tabs = []
      if (this.$themeSettings.components.PageProductDetail.showDescription) {
        tabs.push('description')
      }
      if (this.$themeSettings.components.PageProductDetail.showProductComments) {
        tabs.push('comments')
      }
      if (
        this.$themeSettings.components.PageProductDetail.accessoryProducts.enable &&
        this.accessoryProducts &&
        this.accessoryProducts.length > 0
      ) {
        tabs.push('accessories')
      }
      if (
        this.$themeSettings.components.PageProductDetail.alternativeProducts.enable &&
        this.alternativeProducts &&
        this.alternativeProducts.length > 0
      ) {
        tabs.push('alternatives')
      }
      if (
        this.$store.state.globals.settings.functions.products_user_reviews &&
        this.$themeSettings.components.PageProductDetail.reviews.enable
      ) {
        tabs.push('reviews')
      }
      return tabs
    },
    fetchQuantityFromUrl() {
      const { quantity } = this.$route.query
      if (quantity && !isNaN(quantity)) {
        this.quantity = Number(quantity)
      }
    },
    fetchVariantFromUrl() {
      const { variant_id } = this.$route.query
      const id = variant_id && !isNaN(variant_id) ? Number(variant_id) : this.product.defaultVariantId
      const variant = find(this.product.variants, v => v.id === id) || this.product.variants[0]
      this.displayedVariant = this.selectedVariant = variant
    },
    matchQuantityPrice(type) {
      if (type) {
        const match = this.displayedVariant.prices.quantityPrices.find(o => {
          return o.amountMin > 0 && o.amountMax === 0 ? o : this.quantity >= o.amountMin && this.quantity <= o.amountMax
        })
        return match[type]
      }
    },
    multiplyPrice(price) {
      return price && this.$themeSettings.components.PageProductDetail.multiplyPricesByQuantity
        ? this.quantity * price
        : price
    },
    async openGiftModal(gift) {
      this.activeGift = gift
      if (this.giftModalComponent) {
        this.$refs.giftModal.show()
        return
      }
      import('@theme/components/product/GiftModal').then(component => {
        this.giftModalComponent = component.default
        this.$nextTick(() => {
          this.$refs.giftModal.show()
        })
      })
    },
    async openFittingGuideModal() {
      if (this.fittingGuideModalComponent) {
        this.$refs.fittingGuideModal.show()
        return
      }
      import('@theme/components/product/FittingGuideModal').then(component => {
        this.fittingGuideModalComponent = component.default
        this.$nextTick(() => {
          this.$refs.fittingGuideModal.show()
        })
      })
    },
    scrollToTab(e, tab) {
      if (tab) {
        this.$scrollTo(`#${tab}`)
        if (this.tabActive !== tab) {
          this.tabActive = tab
        }
      } else {
        this.scrollToTabs()
      }
    },
    scrollToTabs() {
      this.$nextTick(() => {
        this.$scrollTo(`#productDetailTabs`)
      })
    },
    selectVariant(e, variant) {
      if (!e || !e.metaKey) {
        if (e) {
          e.stopPropagation()
          e.preventDefault()
        }
        this.selectedVariant = this.displayedVariant = variant
        this.$router.replace({ path: this.$router.path, query: { variant_id: this.selectedVariant.id } })
      }
    },
  },
  watch: {
    '$route.query.variant_id': function() {
      this.fetchVariantFromUrl()
    },
    quantity() {
      return this.quantity
    },
  },
}
