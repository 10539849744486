import { notifications } from '@/app/notifications'
import ProductAddReview from '~/themes/base/components/product/ProductAddReview'

export default {
  extends: ProductAddReview,
  props: {
    canUserReview: Boolean,
  },
  methods: {
    openModal() {
      if (!this.canUserReview) {
        notifications.danger(this.$t('ProductAddReview.cannotReviewText'))
        return
      }

      this.$refs.modal.show()
    },
  },
}
